import { graphql } from 'gatsby'
import React from 'react'

import Breadcrumb from '../../components/breadcrumb'
import Heading from '../../components/heading'
import Layout from '../../components/layout'
import Link from '../../components/link'
import List from '../../components/list'
import ListItem from '../../components/list-item'
import SEO from '../../components/seo'


export const query = graphql`
  query FamiliesSitemapPageQuery {
    ...SiteInfo
    results: postgres {
      families: allFamiliesList(orderBy: SORT_ASC) {
        ...Family
      }
    }
  }
`

export default ({ data }) => {
  const { links } = data.site.siteMetadata
  const { families } = data.results

  const page = 'Note Families Sitemap'
  const title = `${page} - Sitemaps`
  const keywords = title.split(/\W+/)
  const trail = [
    {
      name: 'Sitemap',
      slug: links.sitemap.home,
    },
    {
      name: 'Families',
      slug: links.sitemap.families,
    }
  ]
  const schema = {
    breadcrumb: trail,
    description: title,
    keywords,
    name: title,
    slug: links.sitemap.families,
  }

  return (
    <Layout>
      <SEO keywords={keywords} schema={schema} title={title} />
      <Breadcrumb trail={trail} />

      <Heading>{page}</Heading>

      <List>
        <ListItem>
          <Link href={links.notes}>All Fragrance Families</Link>
        </ListItem>
        {families.map(family => (
          <ListItem key={family.id}>
            <Link href={family.slug}>
              {family.name} Family
            </Link>
          </ListItem>
        ))}
      </List>

      <Breadcrumb trail={trail} />
    </Layout>
  )
}
